<script setup lang="ts"></script>
<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.85714 1.64286C6.85714 1.01168 7.36882 0.5 8 0.5C8.63118 0.5 9.14286 1.01167 9.14286 1.64286V15.3571C9.14286 15.9883 8.63118 16.5 8 16.5C7.36882 16.5 6.85714 15.9883 6.85714 15.3571V1.64286Z"
      fill="black"/>
    <path
      d="M1.14286 9.64286C0.511675 9.64286 2.75899e-08 9.13118 0 8.5C-2.75899e-08 7.86882 0.511675 7.35714 1.14286 7.35714L14.8571 7.35714C15.4883 7.35714 16 7.86882 16 8.5C16 9.13118 15.4883 9.64286 14.8571 9.64286L1.14286 9.64286Z"
      fill="black"/>
  </svg>
</template>
<style lang="scss"></style>
