import { useBreakpoints } from '@vueuse/core';

const breakpoints = useBreakpoints({
  mobile: 526,
  tablet: 1025,
  desktop: 1441
});

export const IS_MOBILE = breakpoints.smaller('mobile');
export const IS_TABLET = breakpoints.smaller('tablet');
