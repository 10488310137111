<script setup lang="ts"></script>
<template>
  <svg fill="none" height="25" viewBox="0 0 25 25" width="25"
       xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="12.5" r="12.5" fill="white"/>
    <path
      d="M14.25 6.25C14.7428 6.25 15.163 6.38254 15.5109 6.64761C15.8587 6.91269 16.0326 7.23299 16.0326 7.60852C16.0326 7.98404 15.8587 8.30434 15.5109 8.56942C15.163 8.83449 14.7428 8.96703 14.25 8.96703C13.7572 8.96703 13.337 8.83449 12.9891 8.56942C12.6413 8.30434 12.4674 7.98404 12.4674 7.60852C12.4674 7.23299 12.6413 6.91269 12.9891 6.64761C13.337 6.38254 13.7572 6.25 14.25 6.25ZM10.3261 10.7314L15.25 10.5741L12.913 16.7205C12.8116 16.9966 12.7609 17.1982 12.7609 17.3252C12.7609 17.4246 12.7862 17.5019 12.837 17.5572C12.8877 17.6069 12.9601 17.6317 13.0543 17.6317C13.2645 17.6317 13.5326 17.513 13.8587 17.2755C14.4022 16.8834 14.9348 16.3284 15.4565 15.6105L16.25 15.9584C14.8949 17.8195 13.4058 18.75 11.7826 18.75C11.2319 18.75 10.7971 18.6257 10.4783 18.3772C10.1594 18.1287 10 17.7891 10 17.3584C10 17.0601 10.0507 16.7785 10.1522 16.5134L11.7717 12.2225C11.808 12.1452 11.8261 12.0651 11.8261 11.9823C11.8261 11.8276 11.7428 11.7062 11.5761 11.6178C11.4094 11.5239 11.1232 11.477 10.7174 11.477H10.0217L10.3261 10.7314Z"
      fill="#2B5DA8"/>
  </svg>
</template>
<style lang="scss"></style>
