import { defineStore } from 'pinia';
import { Prize } from '@/shared/types';
import { reactive } from 'vue';
import { getAdvertisings } from '@/shared/api';
import axios from 'axios';

export const usePrizesStore = defineStore('prizes', {
  state: () => ({
    isListLoading: true,
    prizesList: [] as Prize[]
  }),
  actions: {
    async fetchPrizes () {
      this.isListLoading = true;
      try {
        const { data } = await getAdvertisings();
        this.prizesList = data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
        } else {
          console.log(e);
        }
      }
      this.isListLoading = false;
    }
  }
});

export type ModalProps = {
  activePrize: Prize | undefined,
  isOpen: boolean
};

export const usePrizeModal = () => {
  const modal = reactive<ModalProps>({
    activePrize: undefined,
    isOpen: false
  });

  const toggleModal = ({ activePrize, isOpen }: Partial<ModalProps>) => {
    modal.isOpen = isOpen ?? false;
    modal.activePrize = activePrize ?? undefined;
  };

  return { modal, toggleModal };
};
