import type { AxiosPromise } from 'axios';
import { apiInstance } from './base';
import {
  AttachMsisdnPayload,
  AttachMsisdnResponse,
  AuthResponse,
  SessionInfo
} from '@/shared/types';

const SESSION_INFO_URL = '/games/session/info';
const AUTHORIZE_URL = '/games/session/authorize';
const ATTACH_MSISDN_URL = '/games/session/attach';

export const getSessionInfo = (): AxiosPromise<SessionInfo> => apiInstance.get(SESSION_INFO_URL);
export const authByMsisdn = (msisdn: string): AxiosPromise<AuthResponse> => apiInstance.post(AUTHORIZE_URL, { msisdn });
export const attachMsisdnToSession = (payload: AttachMsisdnPayload): AxiosPromise<AttachMsisdnResponse> => apiInstance.post(ATTACH_MSISDN_URL, payload);
