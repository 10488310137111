/**
 * Фейковый промис для теста
 *
 * @param {string} name - имя функции в которой вызываем.
 * @param {any} data - возвращаемые данные.
 * @param {boolean} success - флаг, отвечающий за успех/неуспех (resolve/reject).
 * @param {number} delay - задержака в мс.
 * @return {Promise}
 * */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const fakeFetch = async function (name, data, success, delay = 2000): Promise<typeof data> {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        console.log('resolve', name);
        resolve(data);
      } else {
        console.log('reject', name);
        reject(new Error(`Error fetching at ${name}`));
      }
    }, delay);
  });
};
