<script setup lang="ts"></script>
<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <rect width="49.0334" height="2.70617"
          transform="matrix(-0.712888 -0.701278 -0.701278 0.712888 38 36)" fill="white"/>
    <rect x="0.999268" y="36" width="49.0334" height="2.70617"
          transform="rotate(-44.5296 0.999268 36)" fill="white"/>
  </svg>
</template>
<style lang="scss"></style>
