import { IS_MOBILE } from '@/shared/helpers';
import { Ref } from 'vue';
import { Draw } from '@/shared/types';
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import { getDraws } from '@/shared/api';
import axios from 'axios';

export const isRepositionedToHeader: Ref<boolean> = IS_MOBILE;

export const useDrawsStore = defineStore('draws', {
  state: () => ({
    isListLoading: true,
    drawsList: [] as Draw[],
    activeDraw: undefined as undefined | Draw
  }),
  getters: {
    currentDraw: (state): Draw | undefined => state.activeDraw,
    countdownActive: (state): boolean => {
      return !!state.drawsList
        .sort((a, b) => {
          const drawUnixA = dayjs(a.draw_date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').valueOf();
          const drawUnixB = dayjs(b.draw_date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').valueOf();
          return drawUnixA - drawUnixB;
        })
        .find(draw => {
          const drawUnix = dayjs(draw.draw_date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').valueOf();
          const nowUnix = dayjs().valueOf();
          return dayjs(drawUnix).isAfter(nowUnix);
        });
    }
  },
  actions: {
    setActiveDraw () {
      const closest = this.drawsList
        .sort((a, b) => {
          const drawUnixA = dayjs(a.draw_date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').valueOf();
          const drawUnixB = dayjs(b.draw_date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').valueOf();
          return drawUnixA - drawUnixB;
        })
        .find(draw => {
          const drawUnix = dayjs(draw.draw_date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').valueOf();
          const nowUnix = dayjs().valueOf();
          return dayjs(drawUnix).isAfter(nowUnix);
        });

      const last = this.drawsList[this.drawsList.length - 1];

      this.activeDraw = closest ?? last;
    },
    async fetchDraws () {
      this.isListLoading = true;
      try {
        const { data } = await getDraws();
        this.drawsList = data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
        } else {
          console.log(e);
        }
      }
      this.setActiveDraw();
      this.isListLoading = false;
    }
  }
});
