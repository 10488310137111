import { RouteRecordRaw } from 'vue-router';
import Routing from './index.vue';
import { IS_MOBILE } from '@/shared/helpers';

const isSidebarHidden = IS_MOBILE;

const routes: Array<unknown | RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/single-ticket'
  },
  {
    path: '/single-ticket',
    name: 'Выбор чисел',
    meta: { listedInNavbar: true },
    components: {
      default: () => import(/* webpackChunkName: "single-ticket" */'@/pages/single-ticket'),
      Header: () => import(/* webpackChunkName: "header" */'@/widgets/header'),
      PrizeList: () => import(/* webpackChunkName: "prize-list" */'@/widgets/prize-list'),
      Footer: () => import(/* webpackChunkName: "footer" */'@/widgets/footer'),
      Sidebar: () => import(/* webpackChunkName: "sidebar" */'@/widgets/sidebar'),
      BuyTicketsModal: () => import(/* webpackChunkName: "buy-tickets-modal" */'@/widgets/buy-tickets-modal'),
      MultiplierPanel: () => import(/* webpackChunkName: "multiplier-panel" */'@/widgets/multiplier-panel'),
      RegistrationModal: () => import(/* webpackChunkName: "registration-modal" */'@/widgets/registration-modal')
    }
  },
  {
    path: '/multi-ticket',
    name: 'Мультибилет',
    meta: { listedInNavbar: true },
    components: {
      default: () => import(/* webpackChunkName: "multi-ticket" */ '@/pages/multi-ticket'),
      Header: () => import(/* webpackChunkName: "header" */'@/widgets/header'),
      PrizeList: () => import(/* webpackChunkName: "prize-list" */'@/widgets/prize-list'),
      Footer: () => import(/* webpackChunkName: "footer" */'@/widgets/footer'),
      Sidebar: () => import(/* webpackChunkName: "sidebar" */'@/widgets/sidebar'),
      BuyTicketsModal: () => import(/* webpackChunkName: "buy-tickets-modal" */'@/widgets/buy-tickets-modal'),
      MultiplierPanel: () => import(/* webpackChunkName: "multiplier-panel" */'@/widgets/multiplier-panel'),
      RegistrationModal: () => import(/* webpackChunkName: "registration-modal" */'@/widgets/registration-modal')
    }
  },
  {
    path: '/rules',
    name: 'Правила лотереи',
    meta: { listedInNavbar: true },
    components: {
      default: () => import(/* webpackChunkName: "rules" */ '@/pages/rules'),
      Header: () => import(/* webpackChunkName: "header" */'@/widgets/header')
    }
  },
  {
    path: '/success/',
    redirect: { name: 'Выбор чисел' },
    components: {
      default: () => import(/* webpackChunkName: "success-page" */ '@/pages/success')
    },
    children: [
      {
        path: ':session',
        redirect: { name: 'Выбор чисел' },
        components: {
          default: () => import(/* webpackChunkName: "success-page" */ '@/pages/success')
        }
      },
      {
        path: ':session/:id',
        name: 'Спасибо за покупку',
        meta: { listedInNavbar: false },
        components: {
          default: () => import(/* webpackChunkName: "success-page" */ '@/pages/success')
        }
      }
    ]
  },
  {
    path: '/error/',
    redirect: { name: 'Выбор чисел' },
    components: {
      default: () => import(/* webpackChunkName: "error-page" */ '@/pages/error')
    },
    children: [
      {
        path: ':session',
        redirect: { name: 'Выбор чисел' },
        components: {
          default: () => import(/* webpackChunkName: "error-page" */ '@/pages/error')
        }
      },
      {
        path: ':session/:id',
        name: 'Произошла ошибка',
        meta: { listedInNavbar: false },
        components: {
          default: () => import(/* webpackChunkName: "error-page" */ '@/pages/error')
        }
      }
    ]
  }
];

export { Routing, routes, isSidebarHidden };
