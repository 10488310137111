<script setup lang="ts"></script>
<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.79815 2.39398C0.357437 1.95327 0.357437 1.23872 0.79815 0.798006C1.23887 0.357281 1.95342 0.357281 2.39415 0.798006L13.566 11.97C14.0067 12.4107 14.0067 13.1252 13.566 13.5659C13.1253 14.0067 12.4107 14.0067 11.97 13.5659L0.79815 2.39398Z"
      fill="black"/>
    <path
      d="M2.39397 13.566C1.95325 14.0067 1.2387 14.0067 0.79798 13.566C0.357266 13.1252 0.357266 12.4107 0.79798 11.97L11.9698 0.79802C12.4105 0.357294 13.1251 0.357294 13.5658 0.798019C14.0065 1.23874 14.0065 1.95328 13.5658 2.394L2.39397 13.566Z"
      fill="black"/>
  </svg>
</template>
<style lang="scss"></style>
