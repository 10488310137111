import { DeclensionWords } from '@/shared/types';
import { translationsModel } from '@/features/translations';
import { storeToRefs } from 'pinia';

/**
 * Склонение слов по числу
 * @param {number} n - число, которое будем склонять
 * @param {Array<string>} titles - массив склонений
 */
export const declensionOfNumbers = (n: number, titles: Array<string>) => {
  if (n % 10 === 1 && n % 100 !== 11) {
    return titles[0];
  } else {
    if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
      return titles[1];
    } else {
      return titles[2];
    }
  }
};

export const useDeclensions = (n: number, word: DeclensionWords) => {
  const {
    ticket_suffixes_1, ticket_suffixes_2, ticket_suffixes_3,
    ticket_number_suffixes_1, ticket_number_suffixes_2, ticket_number_suffixes_3,
    draw_suffixes_1, draw_suffixes_2, draw_suffixes_3
  } = storeToRefs(translationsModel.useTranslationsStore());

  const numberDeclensions = [ticket_number_suffixes_1.value, ticket_number_suffixes_2.value, ticket_number_suffixes_3.value];
  const ticketDeclensions = [ticket_suffixes_1.value, ticket_suffixes_2.value, ticket_suffixes_3.value];
  const drawDeclensions = [draw_suffixes_1.value, draw_suffixes_2.value, draw_suffixes_3.value];

  switch (word) {
    case 'number':
      return declensionOfNumbers(n, numberDeclensions);
    case 'ticket':
      return declensionOfNumbers(n, ticketDeclensions);
    case 'draw':
      return declensionOfNumbers(n, drawDeclensions);
    default:
      return '?????????';
  }
};
