// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// noinspection JSPotentiallyInvalidConstructorUsage

import szModal from 'sz-payment-modal';
import { useTicketsStore } from '@/entities/ticket/model';
import { useAppConfigStore } from '@/shared/config';
import { storeToRefs } from 'pinia';
import * as CryptoJS from 'crypto-js';
import axios from 'axios';

const paymentModuleUrl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_PAYMENT_MODULE_URL_DEV : process.env.VUE_APP_PAYMENT_MODULE_URL_PROD;

const { buyTicketsAdapter } = useTicketsStore();

const { sessionInfo, payModuleIsReady, payToken } = storeToRefs(useAppConfigStore());

export let payModal: typeof szModal;

export const createPayModal = async () => {
  payModuleIsReady.value = false;

  // eslint-disable-next-line new-cap,prefer-const
  payModal = new szModal();

  console.log('payModal created');

  const id = sessionInfo?.value?.merchant_id;
  const key = sessionInfo?.value?.merchant_key;

  const send_data = { player_token: payToken.value };
  const timestamp = Math.floor(Date.now() / 1000);
  const requestBody = JSON.stringify(send_data) + timestamp.toString();

  if (id && key) {
    const sign = CryptoJS.HmacSHA1(requestBody, key);
    const signResult = CryptoJS.enc.Hex.stringify(sign);

    console.log('payModal init started');
    await axios
      .post(paymentModuleUrl, JSON.stringify(send_data), {
        headers: {
          'content-type': 'text/json',
          'X-Merchant-Id': id,
          'X-Timestamp': timestamp,
          'X-Sign': signResult
        }
      })
      .then((response) => {
        payModal.init(response.data, {
          gameLang: sessionInfo?.value?.result.lang.code,
          gameLogoSrc: `${require('../assets/modal_logo.png')}`,
          userToken: payToken.value,
          paymentFunction: buyTicketsAdapter
        });
        console.log('payModal init finished!');

        const modalEl = document.querySelector<Element & Node>('.sz-modal');
        const onClassChange = (classAttrValue: string | null) => {
          const classList = classAttrValue?.split(' ');
          if (classList?.includes('sz-modal--active')) {
            document.body.classList.add('no-overflow');
          } else {
            document.body.classList.remove('no-overflow');
          }
        };

        const observer = new MutationObserver(mutations => {
          for (const mutation of mutations) {
            if (mutation.attributeName) {
              const nV = (mutation.target as HTMLElement).getAttribute(mutation.attributeName);
              onClassChange(nV);
            }
          }
        });

        if (modalEl) {
          observer.observe(modalEl, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ['class']
          });
        }
      })
      .catch((e) => {
        console.log('payModal init failed!');
        throw new Error(e);
      })
      .finally(() => {
        payModuleIsReady.value = true;
      });
  }
};
