import {
  BuyPayloadMultiple,
  BuyPayloadSingle, BuyTicketArguments,
  CombinationsConfig,
  CombinationsNames,
  FinalScreenResponse, PayModalArgsTuple,
  PreviousCombinations,
  Ticket,
  TicketCell
} from '@/shared/types';
import { defineStore, storeToRefs } from 'pinia';
import { getFactorial } from '@/shared/helpers';
import { computed, ComputedRef, markRaw, reactive, toRaw } from 'vue';
import { Swiper as SwiperType } from 'swiper/types/index';
import * as Icon from '@/shared/icons';
import { buyMultipleTickets, buySingleTicket, getCombinations, getTicketsById } from '@/shared/api';
import axios from 'axios';
import { useAppConfigStore } from '@/shared/config';
import { translationsModel } from '@/features/translations';

export const TICKETS_CONFIG = {
  FIRST_FIELD_LENGTH: 20,
  SECOND_FIELD_LENGTH: 4,
  FIRST_FIELD_MINIMUM_CELLS_TO_COMPLETE: 8,
  SECOND_FIELD_MINIMUM_CELLS_TO_COMPLETE: 1,
  FIRST_FIELD_MAXIMUM_SELECTED: 12,
  SECOND_FIELD_MAXIMUM_SELECTED: 4,
  MAXIMUM_TICKETS_TO_PURCHASE_WITH_MULTISELECT: 100,
  SINGLE_TICKET_PRICE: 200
};

type StateCombinationsNames = Exclude<CombinationsNames, 'default'>

interface State {
  singleTicketPrice: number | ComputedRef<number>;
  tickets: Array<Ticket>;
  multiTicket: {
    cost: number,
    count: number,
    multiplier: number
    part_1_count: number,
    part_2_count: number,
  },
  combinations: Record<StateCombinationsNames, PreviousCombinations | undefined>;
}

const { sessionInfo } = storeToRefs(useAppConfigStore());

export const useTicketsStore = defineStore('tickets', {
  state: (): State => ({
    singleTicketPrice: computed(() => sessionInfo?.value?.ticket_cost ?? TICKETS_CONFIG.SINGLE_TICKET_PRICE),
    tickets: [],
    multiTicket: {
      cost: 0,
      count: 1,
      multiplier: 1,
      part_1_count: TICKETS_CONFIG.FIRST_FIELD_MINIMUM_CELLS_TO_COMPLETE,
      part_2_count: TICKETS_CONFIG.SECOND_FIELD_MINIMUM_CELLS_TO_COMPLETE
    },
    combinations: {
      previous: undefined,
      lucky: undefined,
      rare: undefined
    }
  }),
  getters: {
    allTicketsAreComplete: (state: State): boolean => state.tickets.every(ticket => ticket.isComplete),
    completeTickets (state: State): Ticket[] {
      return state.tickets.filter(ticket => ticket.isComplete);
    },
    completeTicketsLength (state: State): number {
      switch (this.router.currentRoute.value.name) {
        case 'Выбор чисел': {
          return this.completeTickets.length;
        }
        case 'Мультибилет': {
          return state.multiTicket.count;
        }
        default: {
          return 0;
        }
      }
    },
    totalTicketsPrice (state: State): number {
      switch (this.router.currentRoute.value.name) {
        case 'Выбор чисел': {
          return this.completeTickets.reduce((previousValue, ticket) => previousValue + ticket.totalPrice, 0);
        }
        case 'Мультибилет': {
          return state.multiTicket.cost;
        }
        default: {
          return 0;
        }
      }
    },
    isBuyButtonVisible (state: State): boolean {
      switch (this.router.currentRoute.value.name) {
        case 'Выбор чисел': {
          return !!this.completeTicketsLength && !!this.totalTicketsPrice && this.totalTicketsPrice !== 0;
        }
        case 'Мультибилет': {
          return state.multiTicket.count > 0;
        }
        default: {
          return false;
        }
      }
    },
    targetMultiplier (state: State): number {
      switch (this.router.currentRoute.value.name) {
        case 'Выбор чисел': {
          return state.tickets.length ? state.tickets[state.tickets.length - 1].multiplier : 0;
        }
        case 'Мультибилет': {
          return state.multiTicket.multiplier;
        }
        default: {
          return 0;
        }
      }
    }
  },
  actions: {
    async fetchCombinations () {
      try {
        const { data } = await getCombinations();
        this.combinations = data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
        } else {
          console.log(e);
        }
      }
    },
    async fetchTicketById (id: number): Promise<FinalScreenResponse | undefined> {
      try {
        const { data } = await getTicketsById(id);
        return data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
        } else {
          console.log(e);
        }
      }
    },
    generateSingleTicket () {
      const firstFieldCellsArray: Array<TicketCell> = reactive([]);
      const secondFieldCellsArray: Array<TicketCell> = reactive([]);

      for (let i = 1; i <= TICKETS_CONFIG.FIRST_FIELD_LENGTH; i++) {
        firstFieldCellsArray.push({
          number: i,
          disabled: false,
          selected: false,
          highlighted: false
        });
      }
      for (let i = 1; i <= TICKETS_CONFIG.SECOND_FIELD_LENGTH; i++) {
        secondFieldCellsArray.push({
          number: i,
          disabled: false,
          selected: false,
          highlighted: false
        });
      }

      const id = Date.now() + (Math.floor((Math.random() * 100000)));

      this.tickets.push({
        activeCombination: 'default',
        isComplete: false,
        totalPrice: 0,
        multiplier: 0,
        id,
        part_1: firstFieldCellsArray,
        part_2: secondFieldCellsArray
      });
    },
    resetMultiTicket () {
      this.multiTicket = {
        cost: sessionInfo?.value?.ticket_cost ?? TICKETS_CONFIG.SINGLE_TICKET_PRICE,
        count: 1,
        multiplier: 1,
        part_1_count: TICKETS_CONFIG.FIRST_FIELD_MINIMUM_CELLS_TO_COMPLETE,
        part_2_count: TICKETS_CONFIG.SECOND_FIELD_MINIMUM_CELLS_TO_COMPLETE
      };
    },
    async buyTickets (payload: BuyTicketArguments): Promise<void> {
      const { payment_method_id, purchase_type, mode } = payload;

      const appConfigStore = useAppConfigStore();
      const {
        purchaseInProgress,
        payToken
      } = storeToRefs(appConfigStore);

      const player_token = payToken.value ?? null;

      let buyPayload: BuyPayloadSingle | BuyPayloadMultiple;

      if (mode === 'Выбор чисел') {
        const cost = this.completeTickets.reduce((previousValue, ticket) => previousValue + ticket.totalPrice, 0);
        const tickets = this.completeTickets.map((ticket: Ticket) => {
          const part_1 = ticket.part_1.filter((cell: TicketCell) => cell.selected).map((cell: TicketCell) => cell.number);
          const part_2 = ticket.part_2.filter((cell: TicketCell) => cell.selected).map((cell: TicketCell) => cell.number);

          return { part_1, part_2 };
        });

        if (!!cost && !!tickets.length && !!payment_method_id) {
          buyPayload = {
            player_token,
            payment_method_id,
            purchase_type,
            cost,
            tickets
          };

          purchaseInProgress.value = true;
          try {
            const { data } = await buySingleTicket(buyPayload);
            window.location.href = data.link;
          } catch (e) {
            if (axios.isAxiosError(e)) {
              console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
            } else {
              console.log(e);
            }
          } finally {
            purchaseInProgress.value = false;
          }
        }
      } else if (mode === 'Мультибилет') {
        const cost = this.multiTicket.cost;
        const count = this.multiTicket.count;
        const part_1_count = this.multiTicket.part_1_count;
        const part_2_count = this.multiTicket.part_2_count;

        if (!!cost && !!count && !!part_1_count && !!part_2_count && !!payment_method_id) {
          buyPayload = {
            player_token,
            payment_method_id,
            purchase_type,
            cost,
            count,
            part_1_count,
            part_2_count
          };

          purchaseInProgress.value = true;
          try {
            const { data } = await buyMultipleTickets(buyPayload);
            window.location.href = data.link;
          } catch (e) {
            if (axios.isAxiosError(e)) {
              console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
            } else {
              console.log(e);
            }
          } finally {
            purchaseInProgress.value = false;
          }
        }
      }
    },
    async buyTicketsAdapter (...args: PayModalArgsTuple): Promise<void> {
      const { registrationModalActive, payModalArgsCache } = storeToRefs(useAppConfigStore());

      const [mode, paymentMethodID, paymentTypeID] = [...args];

      let purchase_type = null;

      if (!(paymentTypeID === null || paymentTypeID === 'null' || !paymentTypeID)) purchase_type = +paymentTypeID;

      const payload = payModalArgsCache.value = {
        mode,
        payment_method_id: +paymentMethodID,
        purchase_type
      };

      console.log(payload);

      if (sessionInfo?.value?.result.no_auth) {
        registrationModalActive.value = true;
      } else {
        await this.buyTickets(payload);
      }
    }
  }
});

const _store = useTicketsStore();

export const useTicketManipulation = () => {
  const ticketCanBeAdded = computed<boolean>(() => _store.allTicketsAreComplete);
  const ticketCanBeDeleted = computed<boolean>(() => _store.tickets.length > 1);

  const addTicket = async (callback: (swiper?: SwiperType) => void) => {
    if (ticketCanBeAdded.value) {
      _store.generateSingleTicket();
      await callback();
    }
  };

  const removeTicket = (id: number) => {
    if (ticketCanBeDeleted.value) {
      _store.$patch((state) => {
        state.tickets = state.tickets.filter(ticket => ticket.id !== id);
      });
    }
  };

  return { ticketCanBeAdded, ticketCanBeDeleted, addTicket, removeTicket };
};

export const useCombinationsModal = () => {
  const {
    combinations_variant_previous,
    combinations_variant_lucky,
    combinations_variant_rare
  } = storeToRefs(translationsModel.useTranslationsStore());

  type ModalProps = { activeCombination: CombinationsNames, activeId: undefined | number, isOpen?: boolean }
  const combinationsConfig: CombinationsConfig[] = [
    {
      name: 'default',
      icon: markRaw(Icon.Config)
    },
    {
      name: 'previous',
      title: computed(() => combinations_variant_previous.value),
      icon: markRaw(Icon.Counterclockwise)
    },
    {
      name: 'lucky',
      title: computed(() => combinations_variant_lucky.value),
      icon: markRaw(Icon.Cup)
    },
    {
      name: 'rare',
      title: computed(() => combinations_variant_rare.value),
      icon: markRaw(Icon.SandWatch)
    }
  ];

  const computedConfig = computed(() => {
    return combinationsConfig.filter(el => {
      const notDefault = el.name !== 'default';
      const isInState = el.name !== 'default' ? _store.combinations[el.name]?.part_1.length && _store.combinations[el.name]?.part_2.length : true;

      return notDefault && isInState;
    });
  });

  const modal = reactive<ModalProps>({
    activeCombination: 'default',
    activeId: undefined,
    isOpen: false
  });
  const openModal = ({ activeCombination, activeId }: ModalProps) => {
    modal.isOpen = true;
    modal.activeCombination = activeCombination;
    modal.activeId = activeId;
  };
  const closeModal = () => {
    modal.isOpen = false;
    modal.activeCombination = 'default';
    modal.activeId = undefined;
  };
  const setActiveCombination = (combinationName: CombinationsNames) => {
    modal.activeCombination = combinationName;

    const targetTicket = _store.tickets.find(ticket => ticket.id === modal.activeId);
    const targetCombination = modal.activeCombination !== 'default' ? _store.combinations[modal.activeCombination] : undefined;

    if (targetTicket) {
      targetTicket.activeCombination = modal.activeCombination;

      if (targetCombination) {
        const combinationPart1 = toRaw(targetCombination).part_1;
        const combinationPart2 = toRaw(targetCombination).part_2;

        targetTicket.part_1.forEach(cell => {
          cell.highlighted = combinationPart1.includes(cell.number);
        });

        targetTicket.part_2.forEach(cell => {
          cell.highlighted = combinationPart2.includes(cell.number);
        });
      } else {
        targetTicket.part_1.forEach(cell => {
          cell.highlighted = false;
        });

        targetTicket.part_2.forEach(cell => {
          cell.highlighted = false;
        });
      }
    }

    closeModal();
  };
  return {
    openModal,
    closeModal,
    setActiveCombination,
    computedConfig,
    modal
  };
};

export const useTicketMultiplier = () => {
  const getFieldMultiplier = (selectedCellsLength: number, partName: 'part_1' | 'part_2'): number => {
    const min = partName === 'part_1' ? TICKETS_CONFIG.FIRST_FIELD_MINIMUM_CELLS_TO_COMPLETE : TICKETS_CONFIG.SECOND_FIELD_MINIMUM_CELLS_TO_COMPLETE;

    const n = getFactorial(selectedCellsLength);
    const k = getFactorial(min);
    let m = selectedCellsLength - min;
    // Если (n-k) равно нулю, то приводим его к 1(единице), т.к. 0! = 1! = 1.
    m = m === 0 ? 1 : m;

    const remaining = getFactorial(m);

    return n / (k * remaining);
  };

  return { getFieldMultiplier };
};
