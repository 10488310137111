<script setup lang="ts"></script>
<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9125 16.2525C14.13 15.405 16.5 12.48 16.5 9C16.5 4.86 13.17 1.5 9 1.5C3.9975 1.5 1.5 5.67 1.5 5.67M1.5 5.67V2.25M1.5 5.67H3.0075H4.83"
      stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2 9C2 12.864 4.688 16 8 16" stroke="black" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" stroke-dasharray="3 3"/>
  </svg>
</template>
<style lang="scss"></style>
