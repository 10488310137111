<script setup lang="ts"></script>
<template>
  <svg fill="none" height="31" viewBox="0 0 30 31" width="30" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15.6699" r="15" fill="white"/>
    <path
      d="M10.2929 16.3768C9.90237 15.9863 9.90237 15.3531 10.2929 14.9626L17.364 7.89153C17.7545 7.501 18.3877 7.501 18.7782 7.89153C19.1687 8.28205 19.1687 8.91522 18.7782 9.30574L11.7071 16.3768C11.3166 16.7673 10.6834 16.7673 10.2929 16.3768Z"
      fill="black"/>
    <path
      d="M10.2929 14.9626C10.6834 14.5721 11.3166 14.5721 11.7071 14.9626L18.7782 22.0337C19.1687 22.4242 19.1687 23.0574 18.7782 23.4479C18.3877 23.8384 17.7545 23.8384 17.364 23.4479L10.2929 16.3768C9.90237 15.9863 9.90237 15.3531 10.2929 14.9626Z"
      fill="black"/>
  </svg>
</template>
<style lang="scss"></style>
