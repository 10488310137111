<script setup lang="ts"></script>
<template>
  <svg width="330" height="5" viewBox="0 0 330 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_1777)">
      <path d="M5.5 0L11 5H0L5.5 0Z" fill="white"/>
      <path d="M16.5 0L22 5H11L16.5 0Z" fill="white"/>
      <path d="M27.5 0L33 5H22L27.5 0Z" fill="white"/>
      <path d="M38.5 0L44 5H33L38.5 0Z" fill="white"/>
      <path d="M49.5 0L55 5H44L49.5 0Z" fill="white"/>
      <path d="M60.5 0L66 5H55L60.5 0Z" fill="white"/>
      <path d="M71.5 0L77 5H66L71.5 0Z" fill="white"/>
      <path d="M82.5 0L88 5H77L82.5 0Z" fill="white"/>
      <path d="M93.5 0L99 5H88L93.5 0Z" fill="white"/>
      <path d="M104.5 0L110 5H99L104.5 0Z" fill="white"/>
      <path d="M115.5 0L121 5H110L115.5 0Z" fill="white"/>
      <path d="M126.5 0L132 5H121L126.5 0Z" fill="white"/>
      <path d="M137.5 0L143 5H132L137.5 0Z" fill="white"/>
      <path d="M148.5 0L154 5H143L148.5 0Z" fill="white"/>
      <path d="M159.5 0L165 5H154L159.5 0Z" fill="white"/>
      <path d="M170.5 0L176 5H165L170.5 0Z" fill="white"/>
      <path d="M181.5 0L187 5H176L181.5 0Z" fill="white"/>
      <path d="M192.5 0L198 5H187L192.5 0Z" fill="white"/>
      <path d="M203.5 0L209 5H198L203.5 0Z" fill="white"/>
      <path d="M214.5 0L220 5H209L214.5 0Z" fill="white"/>
      <path d="M225.5 0L231 5H220L225.5 0Z" fill="white"/>
      <path d="M236.5 0L242 5H231L236.5 0Z" fill="white"/>
      <path d="M247.5 0L253 5H242L247.5 0Z" fill="white"/>
      <path d="M258.5 0L264 5H253L258.5 0Z" fill="white"/>
      <path d="M269.5 0L275 5H264L269.5 0Z" fill="white"/>
      <path d="M280.5 0L286 5H275L280.5 0Z" fill="white"/>
      <path d="M291.5 0L297 5H286L291.5 0Z" fill="white"/>
      <path d="M302.5 0L308 5H297L302.5 0Z" fill="white"/>
      <path d="M313.5 0L319 5H308L313.5 0Z" fill="white"/>
      <path d="M324.5 0L330 5H319L324.5 0Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1_1777">
        <rect width="330" height="5" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<style lang="scss"></style>
