type FunctionArguments = { quantity: number, min: number, max: number }

export const generateArrayOfUniqueNumbers = (Arguments: FunctionArguments): Array<number> => {
  let random: number;
  const arr: Array<number> = [];
  do {
    do {
      random = Math.floor(Math.random() * Arguments.max) + Arguments.min;
    }
    while (arr.includes(random));
    arr.push(random);
  } while (arr.length < Arguments.quantity);
  return arr.sort((a, b) => a - b);
};
