import type { AxiosPromise } from 'axios';
import { apiInstance } from './base';
import {
  BuyPayloadSingle,
  BuyPayloadMultiple,
  FinalScreenResponse
} from '@/shared/types';

const GET_TICKETS_BY_ID_URL = (id: number): string => `/games/tickets/${id}`;
const BUY_URL = '/games/buy';
const BUY_MULTIPLE_URL = '/games/buy_multiple';

export const getTicketsById = (id: number): AxiosPromise<FinalScreenResponse> => apiInstance.get(GET_TICKETS_BY_ID_URL(id));
export const buySingleTicket = (payload: BuyPayloadSingle): AxiosPromise<{ 'link': 'string' }> => apiInstance.post(BUY_URL, payload);
export const buyMultipleTickets = (payload: BuyPayloadMultiple): AxiosPromise<{ 'link': 'string' }> => apiInstance.post(BUY_MULTIPLE_URL, payload);
