import { defineStore } from 'pinia';
import { getSessionInfo } from '@/shared/api';
import axios from 'axios';
import { BuyTicketArguments, SessionInfo } from '@/shared/types';

type State = {
  sessionInfo?: SessionInfo,
  sessionToken: string | undefined,
  payToken: string | null,
  payModuleIsReady: boolean,
  registrationModalActive: boolean,
  payModalArgsCache: BuyTicketArguments | undefined,
  purchaseInProgress: boolean
}

export const useAppConfigStore = defineStore('appConfig', {
  state: (): State => ({
    sessionInfo: undefined,
    sessionToken: undefined,
    payModuleIsReady: false,
    registrationModalActive: false,
    payModalArgsCache: undefined,
    purchaseInProgress: false,
    payToken: null
  }),
  actions: {
    async fetchSessionInfo () {
      try {
        const { data } = await getSessionInfo();
        this.sessionInfo = data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
        } else {
          console.log(e);
        }
      }
    }
  }
});
