<script setup lang="ts"></script>
<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.65 20H7.35002C4.82364 20 3.31532 19.4886 2.41336 18.5867C1.5114 17.6847 1 16.1764 1 13.65V7.35002C1 4.82364 1.5114 3.31532 2.41336 2.41336C3.31532 1.5114 4.82364 1 7.35002 1H13.65C16.1764 1 17.6847 1.5114 18.5867 2.41336C19.4886 3.31532 20 4.82364 20 7.35002V13.65C20 16.1764 19.4886 17.6847 18.5867 18.5867C17.6847 19.4886 16.1764 20 13.65 20Z"
      stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M5.59668 13.1145L8.09569 9.87C8.45269 9.408 9.11419 9.324 9.57619 9.681L11.4977 11.193C11.9597 11.55 12.6212 11.466 12.9782 11.0145L15.4037 7.8855"
      stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<style lang="scss"></style>
