import type { AxiosPromise } from 'axios';
import { apiInstance } from './base';
import { Draw, PreviousCombinations as Combination } from '@/shared/types';

const DRAWS_URL = '/games/draws';
const COMBINATIONS_URL = '/games/tickets/combinations';

export const getDraws = (): AxiosPromise<Draw[]> => apiInstance.get(DRAWS_URL);

interface Combinations {
  previous: Combination;
  lucky: Combination;
  rare: Combination;
}

export const getCombinations = (): AxiosPromise<Combinations> => apiInstance.get(COMBINATIONS_URL);
