<script setup lang="ts"></script>
<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.7916 39.5416H15.2083C14.3333 39.5416 13.3541 38.8541 13.0624 38.0208L4.43743 13.8958C3.20826 10.4375 4.64576 9.37496 7.60409 11.5L15.7291 17.3125C17.0833 18.25 18.6249 17.7708 19.2083 16.25L22.8749 6.47913C24.0416 3.35413 25.9791 3.35413 27.1458 6.47913L30.8124 16.25C31.3958 17.7708 32.9374 18.25 34.2708 17.3125L41.8958 11.875C45.1458 9.54163 46.7083 10.7291 45.3749 14.5L36.9583 38.0625C36.6458 38.8541 35.6666 39.5416 34.7916 39.5416Z"
      stroke="#FECC00" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.5415 45.8333H36.4582" stroke="#FECC00" stroke-width="2.5" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M19.7915 29.1666H30.2082" stroke="#FECC00" stroke-width="2.5" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>
<style lang="scss"></style>
