<script setup lang="ts"></script>
<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.11328 12.375V13.95" stroke="black" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M5 17H13V16C13 14.9 12.28 14 11.4 14H6.6C5.72 14 5 14.9 5 16V17V17Z" stroke="black"
          stroke-width="2" stroke-miterlimit="10"/>
    <path d="M4 17L14 17" stroke="black" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path
      d="M9 12C6.0975 12 3.75 9.6525 3.75 6.75V4.5C3.75 2.8425 5.0925 1.5 6.75 1.5H11.25C12.9075 1.5 14.25 2.8425 14.25 4.5V6.75C14.25 9.6525 11.9025 12 9 12Z"
      stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M4 9C3.34402 8.80274 2.76676 8.4411 2.31195 8.0137C1.52478 7.19178 1 6.20548 1 5.05479C1 3.90411 1.9621 3 3.18659 3H3.7551C3.58018 3.37808 3.49271 3.79726 3.49271 4.23288V6.69863C3.49271 7.52055 3.67638 8.29315 4 9Z"
      stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M14 9C14.656 8.80274 15.2332 8.4411 15.688 8.0137C16.4752 7.19178 17 6.20548 17 5.05479C17 3.90411 16.0379 3 14.8134 3H14.2449C14.4198 3.37808 14.5073 3.79726 14.5073 4.23288V6.69863C14.5073 7.52055 14.3236 8.29315 14 9Z"
      stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<style lang="scss"></style>
