import axios from 'axios';
import { useAppConfigStore } from '@/shared/config';
import { storeToRefs } from 'pinia';
import { router } from '@/app/providers';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
const API_URL = process.env.VUE_APP_API_URL;

export const apiInstance = axios.create({
  baseURL: BACKEND_URL + API_URL
});

apiInstance.interceptors.request.use(config => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const currentRoute = router.currentRoute;

  const { sessionToken } = storeToRefs(useAppConfigStore());

  if (config && config.headers) {
    if (sessionToken.value) {
      config.headers.token = sessionToken.value;
    } else if (params.session) {
      config.headers.token = sessionToken.value = params.session;
    } else if (currentRoute.value.params.session) {
      config.headers.token = sessionToken.value = (currentRoute.value.params.session as string);
    }
  }

  return config;
}, error => {
  console.log('interceptor error', error);
});
