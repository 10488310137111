import { defineStore } from 'pinia';
import { Translations } from '@/shared/types';
import axios from 'axios';
import { getTranslations } from '@/shared/api/translations';

export const useTranslationsStore = defineStore('translations', {
  state: (): Translations => (ru),
  actions: {
    async fetchTranslations () {
      try {
        const { data } = await getTranslations();
        this.$state = { ...this.$state, ...data };
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
        } else {
          console.log(e);
        }
      }
    }
  }
});

const ru = {
  app_title: 'Тиражная лотерея 8 / 20',
  app_page_1: 'Выбор чисел',
  app_page_2: 'Мультибилет',
  app_page_3: 'Правила лотереи',
  ticket_suffixes_1: 'билет',
  ticket_suffixes_2: 'билета',
  ticket_suffixes_3: 'билетов',
  ticket_number_suffixes_1: 'число',
  ticket_number_suffixes_2: 'числа',
  ticket_number_suffixes_3: 'чисел',
  field_1_name: 'Первое поле',
  field_2_name: 'Второе поле',
  field_1_desc_1: 'Выберите',
  field_1_desc_2: 'Выберите еще',
  field_1_desc_3: 'Выбрано максимум. Выбери еще!',
  field_1_desc_4: 'Выбрано максимум, прям вааааще!',
  field_2_desc_1: 'Выберите минимум 1 число',
  field_2_desc_2: 'Выбери еще число',
  field_2_desc_3: 'Выбрано максимум!',
  combinations_title: 'Информация по комбинациям',
  combinations_variant_previous: 'Предыдущий тираж',
  combinations_variant_lucky: 'Часто выпадающие числа',
  combinations_variant_rare: 'Давно не выпадали',
  add_ticket: 'Добавить билет',
  draw_suffixes_1: 'тираж',
  draw_suffixes_2: 'тиража',
  draw_suffixes_3: 'тиражей',
  footer_description: 'СОЦИАЛЬНАЯ ОТВЕТСТВЕННОСТЬ: 15% от операциооного игрового дохода перечисляется на развитие отечественного спорта.',
  simple_words_through: 'через',
  simple_words_buy: 'Купить',
  simple_words_super_prize: 'Суперприз',
  simple_words_lottery: 'Лотерея',
  simple_words_randomise: 'Автовыбор',
  simple_words_sum: 'Сумма',
  rules_title: 'Тиражная лотерея «8/20»',
  rules_desc_1: '«Loto 8/20» - простая и легкая лотерея с высоким шансом на победу. ',
  rules_desc_2: 'Тиражи проходят каждый понедельник в 20:00 на сайте sz.kz с посредством генератора случайных чисел.',
  rules_note_title: 'Пояснения:',
  'rules_note_desc_1 ': 'ПФ (Призовой Фонд) составляет 69% от суммы реализаций на тираж, в том числе 2% резервный фонд тиража.',
  rules_note_desc_2: 'Каждый тираж «Суперприз» увеличивается на 6% от суммы реализации.',
  rules_note_desc_3: 'Если при розыгрыше тиража не был разыгран Суперприз, вся сумма Суперприза переходит в следующий тираж.  ',
  rules_footer_title: 'Важно:',
  rules_footer_desc_1: 'Ты сможешь принять участие в лотерее, если тебе от 18 лет и старше.',
  rules_footer_desc_2: 'Участие в лотерее является добровольным. ',
  rules_footer_desc_3: 'Дата тиража – это не дата покупки билета. ',
  rules_footer_button_title: 'Выиграть может каждый!',
  rules_footer_button_text: 'Выбор билетов ',
  final_success_title: 'Спасибо за покупку',
  final_success_desc: 'Проверяйте статус билетов в разделе “Мои билеты”.',
  final_success_my_tickets: 'Мои билеты',
  final_success_continue: 'Продолжить покупки',
  final_success_bonuses: 'Бонусы за покупку',
  final_success_preview_title: 'Билеты на тираж №',
  final_success_preview_order: 'Заказ № ',
  final_success_buy_date: 'Дата покупки',
  final_error_title: 'Произошла ошибка',
  final_error_desc: 'Видимо что-то случилось.',
  final_error_preview_order: '№ заказа:',
  final_error_back_button: 'Вернуться',
  multiplier_title_default: 'Расширенная ставка',
  multiplier_desc_default: 'Выберите больше чисел и увеличьте вероятность выигрыша!',
  multiplier_title_rising_1: 'Вероятность выигрыша увеличена в',
  multiplier_title_rising_2: 'раз!',
  multiplier_desc_rising: 'Выберите еще больше чисел и увеличьте вероятность выигрыша!',
  multiplier_desc_maximum: 'Выбрано максимальное количество чисел. Нажмите на добавить билет для покупки еще одного билета.',

  simple_words_enter: 'Введите',
  simple_words_phone: 'Телефон',
  registration_description: 'Для регистрации билетов и уведомлениях о выигрышах.',
  registration_agreement: 'Мне есть 18 лет, я принимаю <a href="/">условия оферты</a> Сатты Жулдыз и <a href="/">правила работы кошелька</a>.',
  registration_send_code_text: 'Отправить код',
  registration_resend_text: 'Отправить еще раз',
  registration_validation_wrong_code: 'Неверный код!',
  btns_back: 'Вернуться назад',
  btns_load: 'Загрузка',
  limit_description: 'Лимит на покупку составляет 1 000 000 тг.',
  limit_title: 'Превышен лимит на покупку'
};

/* const kk = {
  app_title: '8/20 тираждық  лотереясы',
  app_page_1: 'Сандарды таңдау',
  app_page_2: 'Мультибилет',
  app_page_3: 'Лотерея ережелері',
  ticket_suffixes_1: 'билет',
  ticket_suffixes_2: 'билет',
  ticket_suffixes_3: 'билет',
  ticket_number_suffixes_1: 'сан',
  ticket_number_suffixes_2: 'сан',
  ticket_number_suffixes_3: 'сан',
  field_1_name: 'Бірінші алаң',
  field_2_name: 'Екінші алаң',
  field_1_desc_1: 'Таңдаңыз',
  field_1_desc_2: 'Тағы таңдаңыз',
  field_1_desc_3: 'Максимум таңдалды. Тағы таңдаңыз!',
  field_1_desc_4: 'Максимум таңдалды, мүлдем дерлік!',
  field_2_desc_1: 'Кем дегенде 1 сан таңдаңыз',
  field_2_desc_2: 'Тағы бір сан таңдаңыз',
  field_2_desc_3: 'Максимум таңдалды!',
  combinations_title: 'Комбинациялар бойынша ақпарат',
  combinations_variant_previous: 'Алдыңғы тираж',
  combinations_variant_lucky: 'Жиі түсетін сандар',
  combinations_variant_rare: 'Көптен бері түспеген',
  add_ticket: 'Билет қосу',
  draw_suffixes_1: 'тираж',
  draw_suffixes_2: 'тираж',
  draw_suffixes_3: 'тираж',
  footer_description: 'ӘЛЕУМЕТТІК ЖАУАПКЕРШІЛІК: операциялық ойын кірісінің 15%-ы отандық спортты дамытуға аударылады.',
  simple_words_through: 'арқылы',
  simple_words_buy: 'Сатып алу',
  simple_words_super_prize: 'Супержүлде',
  simple_words_lottery: 'Лотерея',
  simple_words_randomise: 'Автотаңдау',
  simple_words_sum: 'Сома',
  rules_title: '«8/20» тираждық лотереясы',
  rules_desc_1: '"Loto 8/20" - ұтудың жоғары мүмкіндігі бар қарапайым және жеңіл лотерея. ',
  rules_desc_2: 'Тираждар кездейсоқ сандар генераторы арқылы әр дүйсенбі күні 20:00-де sz.kz сайтында өтеді.',
  rules_note_title: 'Түсініктемелер:',
  'rules_note_desc_1 ': 'ЖҚ (жүлде қоры) тиражға сату сомасының 69%-ын құрайды, оның ішінде 2%-ы резервтік қор.',
  rules_note_desc_2: 'Әрбір тираж сайын «Супержүлде» сату сомасынан 6%-ға ұлғаяды.',
  rules_note_desc_3: 'Егер ұтыс ойыны кезінде Супержүлде ойнатылмаса, Супержүлденің барлық сомасы келесі тиражға өтеді.',
  rules_footer_title: 'Маңызды:',
  rules_footer_desc_1: 'Егер сіз 18 жастан асқан болсаңыз, лотереяға қатыса аласыз.',
  rules_footer_desc_2: 'Лотереяға қатысу ерікті болып табылады.',
  rules_footer_desc_3: 'Тираж күні билетті сатып алу күні емес.',
  rules_footer_button_title: 'Барлығы ұта алады!',
  rules_footer_button_text: 'Билеттерді таңдау',
  final_success_title: 'Сатып алғаныңызға рахмет',
  final_success_desc: 'Билеттердің мәртебесін «Менің билеттерім» бөлімінде тексеріңіз.',
  final_success_my_tickets: 'Менің билеттерім',
  final_success_continue: 'Сатып алуды жалғастыру',
  final_success_bonuses: 'Сатып алу бонустары',
  final_success_preview_title: '№ тиражға билеттер',
  final_success_preview_order: 'Тапсырыс №',
  final_success_buy_date: 'Сатып алу күні',
  final_error_title: 'Қателік орын алды',
  final_error_desc: 'Бірдеңе болған сияқты.',
  final_error_preview_order: 'тапсырыс №:',
  final_error_back_button: 'Кері қайту',
  multiplier_title_default: 'Кеңейтілген ұтыс тігу',
  multiplier_desc_default: 'Көбірек сандарды таңдап, ұтып алу мүмкіндігін арттырыңыз!',
  multiplier_title_rising_1: 'Ұтып алу ықтималдығы ',
  multiplier_title_rising_2: 'есеге артты!',
  multiplier_desc_rising: 'Одан да көп нөмірлерді таңдап, ұтып алу мүмкіндігін арттырыңыз!',
  multiplier_desc_maximum: 'Сандардың ең көп мөлшері таңдалды. Басқа билетті сатып алу үшін билет қосу батырмасын басыңыз.',

  simple_words_enter: 'Введите',
  simple_words_phone: 'Телефон',
  registration_description: 'Для регистрации билетов и уведомлениях о выигрышах.',
  registration_agreement: 'Мне есть 18 лет, я принимаю <a href="/">условия оферты</a> Сатты Жулдыз и <a href="/">правила работы кошелька</a>.',
  registration_send_code_text: 'Отправить код',
  registration_resend_text: 'Отправить еще раз',
  registration_validation_wrong_code: 'Неверный код!',
  btns_back: 'Вернуться назад',
  btns_load: 'Загрузка',
  limit_description: 'Лимит на покупку составляет 1 000 000 тг.',
  limit_title: 'Превышен лимит на покупку'
}; */
