// noinspection JSIgnoredPromiseFromCall

import { app } from './app';
import { drawModel } from '@/entities/draw';
import { prizeModel } from '@/entities/prizes';
import { ticketModel } from '@/entities/ticket';
import { rulesModel } from '@/entities/rules';
import { translationsModel } from '@/features/translations';
import { useAppConfigStore } from '@/shared/config';
import { router } from '@/app/providers';
import { createPayModal } from '@/shared/ui';

const { useDrawsStore } = drawModel;
const { usePrizesStore } = prizeModel;
const { useTicketsStore } = ticketModel;
const { useRulesStore } = rulesModel;
const { useTranslationsStore } = translationsModel;

const { fetchDraws } = useDrawsStore();
const { fetchPrizes } = usePrizesStore();
const { fetchCombinations } = useTicketsStore();
const { fetchRules } = useRulesStore();
const { fetchSessionInfo } = useAppConfigStore();
const { fetchTranslations } = useTranslationsStore();

const initApp = async () => {
  app.mount('#app');

  await router.isReady();
  await fetchSessionInfo();
  await fetchDraws();
  await fetchPrizes();
  await fetchCombinations();
  await fetchRules();
  await fetchTranslations();

  await createPayModal();
};

initApp();
