import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';
import { routes } from '@/pages';
import { computed } from 'vue';
import { useTitle } from '@vueuse/core';
import { useAppConfigStore } from '@/shared/config';
import { storeToRefs } from 'pinia';

export const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes as RouteRecordRaw[]
});

router.beforeEach((to, from, next) => {
  if (to.query.session) {
    next();
  } else if (from.query.session) {
    next({ path: to.path, query: from.query });
  } else {
    next();
  }
});

router.beforeResolve((to, from) => {
  const { payToken } = storeToRefs(useAppConfigStore());
  if (payToken.value === null) {
    if (to.query.player_token || to.query.token) {
      if (to.query.player_token) {
        payToken.value = to.query.player_token.toString();
        return;
      }
      if (to.query.token) {
        payToken.value = to.query.token.toString();
        return;
      }
    }

    if (from.query.player_token || from.query.token) {
      if (from.query.player_token) {
        payToken.value = from.query.player_token.toString();
        return;
      }
      if (from.query.token) payToken.value = from.query.token.toString();
    }
  }
});

const pageTitle = computed(() => {
  return (router.currentRoute.value && router.currentRoute.value.name)
    ? `${String(router.currentRoute.value.name)} | 8.20`
    : '8.20';
});

useTitle(pageTitle);
