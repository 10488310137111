import { Rule } from '@/shared/types';
import { defineStore } from 'pinia';
import { getRules } from '@/shared/api';
import axios from 'axios';

/* const FAKE_RULES = [
  {
    id: 1,
    ordnum: 1,
    title: 'Как играть',
    text: 'Перед тобой два поля. Первое поле состоит из чисел от 1 до 20 из которых тебе нужно выбрать 8 чисел. Второе поле состоит из чисел от 1 до 4, из которых тебе нужно выбрать одно чисел. Если выбранные тобой числа совпадут с числами из тиража, то выигрыш тебе обеспечен.'
  },
  {
    id: 2,
    ordnum: 2,
    title: 'Как оплатить?',
    text: 'Оплатить билеты банковской картой, балансом телефона или Qiwi кошельком. Также ты можешь оплатить с помощью кошелька, а, чтобы деньги были в кошельке, нужно всего лишь пополнить его с помощью любого доступного метода.'
  },
  {
    id: 3,
    ordnum: 3,
    title: 'Сколько можно выиграть?',
    text: '   Цена одной лотерейной комбинации — 200 (двести) тенге\n' +
      '        <table>\n' +
      '          <thead>\n' +
      '          <tr>\n' +
      '            <th>Категория</th>\n' +
      '            <th>Количество совпадений</th>\n' +
      '            <th>Выигрыш</th>\n' +
      '          </tr>\n' +
      '          </thead>\n' +
      '          <tbody>\n' +
      '          <tr>\n' +
      '            <td>1</td>\n' +
      '            <td>8+1</td>\n' +
      '            <td>5% (минимум 1 000 000 тенге) + переходящий ПФ</td>\n' +
      '          </tr>\n' +
      '          <tr>\n' +
      '            <td>x</td>\n' +
      '            <td>x+x</td>\n' +
      '            <td>x%</td>\n' +
      '          </tr>\n' +
      '          <tr>\n' +
      '            <td>x</td>\n' +
      '            <td>x+x</td>\n' +
      '            <td>x%</td>\n' +
      '          </tr>\n' +
      '          <tr>\n' +
      '            <td>x</td>\n' +
      '            <td>x+x</td>\n' +
      '            <td>x%</td>\n' +
      '          </tr>\n' +
      '          <tr>\n' +
      '            <td>x</td>\n' +
      '            <td>x+x</td>\n' +
      '            <td>x%</td>\n' +
      '          </tr>\n' +
      '          <tr>\n' +
      '            <td>x</td>\n' +
      '            <td>x+x</td>\n' +
      '            <td>x%</td>\n' +
      '          </tr>\n' +
      '          </tbody>\n' +
      '        </table>'
  },
  {
    id: 4,
    ordnum: 4,
    title: 'Где узнать результат?',
    text: 'Строка результатов текущего тиража находится над игровым полем. А если тебе необходимы результаты прошедших тиражей, то переходи в раздел Архивы тиражей.'
  },
  {
    id: 5,
    ordnum: 5,
    title: 'Где получить выигрыш билетов, купленных на sz.KZ?',
    text: 'Сумму до 6 МРП автоматически падают на баланс кошелька.\nДля получения суммы от 6 МРП до 100 000 тенге нужно заполнить полностью свой профиль, обязательно приложить удостоверяющий документ личность.\nВывести с кошелька свои выигрыши можно на банковскую карту, баланс телефона, qiwi или касса24.\nНО, если сумма больше 100 000 тенге, то их отправят на карту! Нужно только посетить наш головной или региональные офисы, но когда соберешься за выигрышем, не забудь:\nУдостоверение личности.\nIBAN карты, его можно получить в отделении банка или скачать в приложении банка.\nВыигрыши свыше 17 502 тенге облагаются налогом. Поэтому выигрыш, согласно налоговому законодательству, получишь с минусом 10% индивидуального подоходного налога для резидентов РК. Для нерезидентов 20% сумма ИПН.'
  }
]; */

interface State {
  isListLoading: boolean;
  rulesList: Array<Rule>;
}

export const useRulesStore = defineStore('rules', {
  state: (): State => ({
    isListLoading: false,
    rulesList: []
  }),
  actions: {
    async fetchRules () {
      this.isListLoading = true;
      try {
        const { data } = await getRules();
        this.rulesList = data;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error(`${e.message} WHEN ${e.config.method} TO ${e.config.url}`);
        } else {
          console.log(e);
        }
      }
      this.isListLoading = false;
    }
  }
});
